<template>
  <div class="custom-targeting-list-table">
    <div v-if="loading && genericData.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5">
          <!--begin::Table head-->
          <thead>
          <!--begin::Table row-->
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-100px" v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
            </tr>
          <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-semibold text-gray-600">
          <LoaderOverlay
            size="big"
            color="color2"
            v-if="loading"
          />
          <!--begin::Table row-->
            <tr v-for="(data, index) in genericData" :key="index">
              <td>
                <span>{{ data.name }}</span>
              </td>
              <td>
                <span>{{ data.type }}</span>
              </td>
              <td>
                <span>{{ data.platform }}</span>
              </td>
              <td>
                <span>{{ data.status | formatStatus }}</span>
              </td>
            </tr>
          <!--end::Table row-->
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
      <div class="text-center mt-4">
        <Button
          class="btn-primary"
          :loading="loading"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay.vue'

export default {
  name: 'GenericTable',
  props: ['genericData', 'reload', 'isLoadMoreVisible', 'loading', 'tableHeaders'],
  components: {
    LoaderOverlay,
    Button,
    Loader
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  methods: {
    /**
     * Load more
     */
    loadMore () {
      this.$emit('load-more')
    }
  }
}
</script>
