import axios from '@/plugins/axios'

const getAudiences = async ({
  advertiserId,
  types,
  page = 1,
  search = null,
  isActive = null
}) => {
  try {
    const response = await axios.get('admin/audiences', {
      params: {
        page: page,
        search: search,
        is_active: isActive
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

const upsertAudiences = async ({
  file
}) => {
  const form = new FormData()
  form.append('file', file)

  try {
    const response = await axios.post('admin/audiences', form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getAudiences,
  upsertAudiences
}
